a.navbar-brand img{
  height: 48px;
  float: left;
  margin-right: 7px;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

/*Navbar override*/

.navbar .navbar-nav .nav-item .nav-link {
  font-weight: 400;
  font-size: 14px;
  color: #333;
}

/* CT Overrides */

.nav-btn{
  padding: 8px 18px;
  background-color: #31a2db !important;
}

.logo-image {
  height: 46px;
  overflow: hidden;
  margin-top: -6px;
}