@import '../node_modules/@syncfusion/ej2-base/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/bootstrap.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/bootstrap.css";
/* Provide sufficient contrast against white background */

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.clip-fix {
  margin-top: 3em;
}

.vcenter {
  display: flex;
  align-items: center;
}

.bolder {
  font-weight: 500;
}

.btn-bolder {
  font-weight: 600;
}

.text-bold {
  font-weight: bold;
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.clickable {
  cursor: pointer;
}

.konsblue {
  color: #31a2db !important;
}

.technologies {
  display: block;
  margin: 70px auto 40px;
  text-align: center;
}

.small-info, .technologies {
  color: #666666;
  position: relative;
}

.technologies .mask:not(:last-child) {
  margin-right: 8px;
}

.technologies .mask {
  display: inline-block;
  width: 52px;
  height: 52px;
  overflow: hidden;
  border-radius: 50%;
  margin-top: 10px;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.05);
}

.technologies .mask img {
  width: 100%;
}

.product-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-flex .product-details {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 16px 0;
  color: #666;
  font-weight: 300;
}

.product-flex .product-details .material-icons {
  margin-right: 15px;
  font-size: 24px;
  color: #00bcd4;
}

.product-flex .product-details h6 {
  font-size: 1rem;
}

.white-bg {
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 90%, rgba(237, 237, 237, 1) 100%);
}

li.list-item-break {
  margin: 0;
  display: block;
}

.btn-install {
  background-color: #31a2db !important;
}

.details-desc {
  font-weight: 500;
  margin-bottom: 3em;
}

.details-form {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

div.report-wrapper {
  margin: 0 15px 0;
}

div.report-header {
  margin-top: 2em;
  margin-bottom: 0;
}

h1.report-title {
  margin-top: 1em;
}

.pagination-wrapper {
  margin-top: 1em;
}

.equal-card-height {
  min-height: 130px;
}

/* Clamped text */

.widget-card .clamped-text button {
  background-color: transparent;
  box-shadow: none;
  padding: 0.40625rem 1.25rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  outline: 0;
  border: 0;
  -webkit-transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow, transform;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 600;
  position: relative;
  margin: 0.3125rem 1px;
}

.widget-card .card-footer .clamped-text button {
  border-color: #31a2db;
  color: #31a2db;
}

.widget-card .card-header .clamped-text button {
  border-color: inherit;
  color: inherit;
  padding: 0;
}

/* Scorecard */

div.scorecard {
  margin-bottom: 10px;
}

div.scorecard .scorecard-body {
  margin: 0
}

div.scorecard h2.score {
  font-size: 4rem;
}

div.scorecard h2.score i.material-icons {
  font-size: 3rem;
}

@media(max-width: 768px) {
  div.scorecard h2.score {
    font-size: 3rem;
  }
  div.scorecard h2.score i.material-icons {
    font-size: 2rem;
  }
}

/* Notification badge */

.badge.notification {
  position: absolute;
  right: 10px;
}

/* Print and Re-run Button */

div.print-button-wrapper, div.re-run-button-wrapper {
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 10;
}

div.print-button-wrapper button.print-button {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

div.print-button-wrapper button.print-button i.material-icons, div.re-run-button-wrapper button.re-run-button i.material-icons{
  font-size: 2em;
}

/* Product Form */

.productForm * {
  font-weight: 500;
}

.productForm label.control-label {
  color: #31a2db;
}

.productForm label.control-label:after {
  content: ':';
}

#sitecollectionurl-label {
  font-size: 85%;
}

/*Carousel override*/

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2331a2db' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2331a2db' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

#productCarousel {
  min-height: 150px;
}

/*Footer*/

footer {
  padding-top: 2.5em;
}

footer h5 {
  text-align: left;
}

footer li.list-group-item {
  padding: 0;
}

footer .bold * {
  font-weight: 400;
}

/* Overrides */

.page-header-short {
  height: 80vh;
}

ul.error-detail {
  list-style-type: none;
}

/* Color picker */

select.colour-picker.red {
  color: #d13438;
}

select.colour-picker option.red {
  background-color: #d13438;
  color: #FFF;
}

select.colour-picker option.red:hover {
  color: #d13438;
  background-color: #FFF;
}

select.colour-picker.green {
  color: #10893e;
}

select.colour-picker option.green {
  background-color: #10893e;
  color: #FFF;
}

select.colour-picker option.green:hover {
  color: #10893e;
  background-color: #FFF;
}

select.colour-picker.blue {
  color: #0078d7;
}

select.colour-picker option.blue {
  background-color: #0078d7;
  color: #FFF;
}

select.colour-picker option.blue:hover {
  color: #0078d7;
  background-color: #FFF;
}

select.colour-picker.orange {
  color: #ca5010;
}

select.colour-picker option.orange {
  background-color: #ca5010;
  color: #FFF;
}

select.colour-picker option.orange:hover {
  color: #ca5010;
  background-color: #FFF;
}

select.colour-picker.purple {
  color: #6b69d6;
}

select.colour-picker option.purple {
  background-color: #6b69d6;
  color: #FFF;
}

select.colour-picker option.purple:hover {
  color: #6b69d6;
  background-color: #FFF;
}

select.colour-picker.gray {
  color: #5d5a58;
}

select.colour-picker option.gray {
  background-color: #5d5a58;
  color: #FFF;
}

select.colour-picker option.gray:hover {
  color: #5d5a58;
  background-color: #FFF;
}

/* BS Callouts */

.bs-callout {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;
}

.bs-callout h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.bs-callout p:last-child {
  margin-bottom: 0;
}

.bs-callout code {
  border-radius: 3px;
}

.bs-callout+.bs-callout {
  margin-top: -5px;
}

.bs-callout-default {
  border-left-color: #777;
}

.bs-callout-default h4 {
  color: #777;
}

.bs-callout-primary {
  border-left-color: #31a2db;
}

.bs-callout-primary h4 {
  color: #31a2db;
}

.bs-callout-success {
  border-left-color: #4caf50;
}

.bs-callout-success h4 {
  color: #4caf50;
}

.bs-callout-danger {
  border-left-color: #f44336;
}

.bs-callout-danger h4 {
  color: #f44336;
}

.bs-callout-warning {
  border-left-color: #ff9800;
}

.bs-callout-warning h4 {
  color: #ff9800;
}

.bs-callout-info {
  border-left-color: #00a5bb;
}

.bs-callout-info h4 {
  color: #00a5bb;
}