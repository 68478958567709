.loading img{
    animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}